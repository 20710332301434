import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import {
  contactListItem,
  contactLink,
  contactInfo,
  contactList,
  verticallyCentreAtSymbol,
  twitterIcon,
} from "./contact-info.module.styl"
import getClass from "../utils/get-class"
import twitterIconImage from "../images/twitter.svg"

function ContactItem({ children, label }) {
  return (
    <li className={contactListItem} aria-label={label}>
      {children}
    </li>
  )
}

function ContactLinkItem({ children, href, label, beforeLink = null }) {
  return (
    <ContactItem label={label}>
      {beforeLink}
      <OutboundLink
        className={contactLink}
        href={href}
        target="_blank"
        rel="noopener"
      >
        {children}
      </OutboundLink>
    </ContactItem>
  )
}

function ContactInfo({ className }) {
  const {
    site: {
      siteMetadata: { email, twitter },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
            twitter
          }
        }
      }
    `
  )

  const [emailUser, emailDomain] = email.split("@")

  return (
    <section
      aria-label="contact info"
      className={getClass(className, contactInfo)}
    >
      <ul className={contactList}>
        <ContactLinkItem href={`mailto:${email}`} label="email">
          {emailUser}
          <span className={verticallyCentreAtSymbol}>@</span>
          {emailDomain}
        </ContactLinkItem>
        <ContactLinkItem
          href={`https://twitter.com/${twitter}`}
          label="twitter"
          beforeLink={
            <img
              className={twitterIcon}
              src={twitterIconImage}
              alt=""
              role="presentation"
            />
          }
        >
          <span className={verticallyCentreAtSymbol}>@</span>
          {twitter}
        </ContactLinkItem>
      </ul>
    </section>
  )
}

ContactInfo.defaultProps = {}

ContactInfo.propTypes = {
  className: PropTypes.string,
}

export default ContactInfo
