import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import {
  header,
  appHeading,
  appHeadingImage,
  appSubHeading,
  appContent,
  footer,
  footerCopyright,
} from "./layout.module.styl"
import "../styles/global.styl"

export default function Layout({ children }) {
  var year = new Date().getFullYear()

  const {
    file: { image: slydrTitle },
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "slydr/slydr_title.png" }) {
          image: childImageSharp {
            gatsbyImageData(
              width: 459
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  return (
    <>
      <header className={header}>
        <h1 className={appHeading}>
          <GatsbyImage
            image={slydrTitle.gatsbyImageData}
            className={appHeadingImage}
            alt="slydr"
          />
        </h1>
        <h2 className={appSubHeading}>Sliding Puzzle Game</h2>
      </header>
      <main className={appContent}>{children}</main>
      <footer className={footer}>
        <OutboundLink className={footerCopyright} href="https://sqog.games">
          © {year} sqog
        </OutboundLink>
      </footer>
    </>
  )
}
