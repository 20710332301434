import * as React from "react"
import {
  trailerWrapper,
  trailerInnerWrapper,
  trailerPlayer,
} from "./trailer.module.styl"

function Trailer() {
  return (
    <div className={trailerWrapper}>
      <div className={trailerInnerWrapper}>
        <iframe
          className={trailerPlayer}
          title="Slydr trailer YouTube embed"
          src="https://www.youtube-nocookie.com/embed/y1jA8iCQNNA"
          loading="lazy"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

Trailer.defaultProps = {}

Trailer.propTypes = {}

export default Trailer
