import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { getSrc } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const TRAILING_SLASH = /\/$/
const LEADING_SLASH = /^\//

function SEO({ description, title }) {
  const {
    logoImage: {
      image: { gatsbyImageData: logoImage },
    },
    site,
  } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            author
            twitter
            siteUrl
            appId
          }
        }
        logoImage: file(relativePath: { eq: "slydr/slydr_logo_full.png" }) {
          image: childImageSharp {
            gatsbyImageData(width: 512, layout: CONSTRAINED)
          }
        }
      }
    `
  )

  const normalisedSiteUrl = site.siteMetadata.siteUrl.replace(
    TRAILING_SLASH,
    ""
  )
  function toAbsoluteUrl(path) {
    return [normalisedSiteUrl, path.replace(LEADING_SLASH, "")].join("/")
  }

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title

  return (
    <Helmet titleTemplate={title ? `${site.siteMetadata.title} | %s` : `%s`}>
      <html lang="en" />

      <title>{title || site.siteMetadata.title}</title>

      <meta name="description" content={metaDescription} />

      <meta name="og:title" content={metaTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />

      <meta name="twitter:card" content="app" />
      <meta name="twitter:site" content={`@${site.siteMetadata.twitter}`} />
      <meta name="twitter:title" content={site.siteMetadata.title} />
      <meta
        name="twitter:description"
        content={site.siteMetadata.description}
      />
      <meta name="twitter:app:country" content="UK" />
      <meta
        name="twitter:app:name:googleplay"
        content={site.siteMetadata.title}
      />
      <meta
        name="twitter:app:id:googleplay"
        content={site.siteMetadata.appId}
      />

      <script type="application/ld+json">{`
      {
        "@context": "https://schema.org",
        "@type": "MobileApplication",
        "applicationCategory": "Game",
        "applicationSubCategory": "Puzzle Game",
        "author": {
          "@type": "Organization",
          "name": "${site.siteMetadata.author}",
          "description": "sqog games is an independent studio producing fun new games for mobile and desktop",
          "url": "https://sqog.games"
        },
        "name": "${site.siteMetadata.title}",
        "description": "${site.siteMetadata.description}",
        "url": "${site.siteMetadata.siteUrl}",
        "image": "${toAbsoluteUrl(getSrc(logoImage))}",
        "installUrl": "https://play.google.com/store/apps/details?id=${
          site.siteMetadata.appId
        }&utm_source=slydr.app.jsonld&utm_campaign=homepages",
        "operatingSystem": "Android 4.4"
      }
      `}</script>
    </Helmet>
  )
}

SEO.defaultProps = {}

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
}

export default SEO
