import * as React from "react"
import {
  slydrInfoBlock,
  slydrInfoList,
  slydrInfoListItem,
  slydrInfo,
} from "./slydr-info.module.styl"

const SlydrInfoBlock = props => <p {...props} className={slydrInfoBlock} />
const SlydrInfoList = props => <ul {...props} className={slydrInfoList} />
const SlydrInfoListItem = props => (
  <li {...props} className={slydrInfoListItem} />
)

function SlydrInfo() {
  return (
    <section className={slydrInfo}>
      <SlydrInfoBlock>
        Slydr is a fun, new, colourful, pattern solving, sliding puzzle game
        which mixes elements of the Rubik’s Cube with a classic sliding tile
        puzzle to create a simple game requiring skill and careful thinking to
        solve.
      </SlydrInfoBlock>

      <SlydrInfoBlock>
        Slydr works a bit like a 2D Rubik’s Cube, where the game pieces rotate
        around infinitely - if you slide a disc off to the left, it immediately
        appears from the right; when you push one out at the top, it comes back
        in from the bottom, and so on.
      </SlydrInfoBlock>
      <SlydrInfoBlock>
        The challenge is to slide the discs back over their matching-coloured
        rings in the smallest number of moves possible. This starts out as an
        easy enough task - just moving one piece to the correct place; but as
        the number of shuffles and colours increases, the puzzles quickly get
        much more difficult to solve and you’ll need to really exercise your
        brain in order to find the best solutions.
      </SlydrInfoBlock>
      <SlydrInfoBlock>There are two game modes to try:</SlydrInfoBlock>
      <SlydrInfoList>
        <SlydrInfoListItem>
          - Free Play - try to solve levels in the minimum number of moves
          without the pressure of a timer
        </SlydrInfoListItem>
        <SlydrInfoListItem>
          - Time Attack - given a small amount of time to look at the board and
          plan, you then have a limited clock to actually shuffle the pieces
          into the correct order before time runs out
        </SlydrInfoListItem>
      </SlydrInfoList>
    </section>
  )
}

SlydrInfo.defaultProps = {}

SlydrInfo.propTypes = {}

export default SlydrInfo
