import * as React from "react"
import ContactInfo from "../components/contact-info"
import DownloadLink from "../components/download-link"
import Layout from "../components/layout"
import SlydrTrailer from "../components/trailer"
import SlydrInfo from "../components/slydr-info"
import Seo from "../components/seo"
import {
  indexContent,
  slydrTrailerWrapper,
  slydrInfoWrapper,
  downloadLinkWrapper,
  contactInfo,
} from "./index.module.styl"

export default function Index() {
  return (
    <Layout>
      <Seo />
      <div className={indexContent}>
        <div className={slydrTrailerWrapper}>
          <SlydrTrailer />
        </div>
        <div className={slydrInfoWrapper}>
          <SlydrInfo />
        </div>
        <div className={downloadLinkWrapper}>
          <DownloadLink />
        </div>
        <ContactInfo className={contactInfo} />
      </div>
    </Layout>
  )
}
