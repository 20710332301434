import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { downloadLink, downloadLinkImage } from "./download-link.module.styl"

function DownloadLink() {
  const {
    file: { image: playStoreBadge },
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "slydr/play_store_download_badge.png" }) {
          image: childImageSharp {
            gatsbyImageData(
              width: 646
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  return (
    <OutboundLink
      className={downloadLink}
      href="https://play.google.com/store/apps/details?id=games.sqog.slydr&utm_source=slydr.app&utm_campaign=homepages&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      target="_self"
      rel="noopener"
    >
      <GatsbyImage
        image={playStoreBadge.gatsbyImageData}
        className={downloadLinkImage}
        width="280"
        height="108.36"
        alt="Download Slydr on Google Play"
      />
    </OutboundLink>
  )
}

DownloadLink.defaultProps = {}

DownloadLink.propTypes = {}

export default DownloadLink
